<template>
  <v-card height="100px" flat>
    <div class="d-flex flex-column align-center justify-center fill-height">
      <v-card-text class="text-center">{{ content }}</v-card-text>
      <v-card-actions class="d-flex justify-center" v-if="!hideButton">
        <v-btn color="blue" outlined @click="buttonClicked">
          {{ buttonText }}
        </v-btn>
      </v-card-actions>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: "No data"
    },
    buttonText: {
      type: String,
      default: "Add new"
    },
    hideButton: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    buttonClicked() {
      this.$emit("button-click")
    }
  }
}
</script>
