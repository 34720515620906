<template>
  <v-dialog v-model="visible" width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        color="teal"
        depressed
        icon
        small
        @click="fetchContactAddresses(contactId)"
      >
        <v-icon small>mdi-bank</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-card-title>
        <v-row justify="space-between">
          <v-col>
            {{ fullName }}
          </v-col>
          <v-col cols="auto" v-if="primaryContact">
            <v-chip color="green" text-color="white" small>
              <v-avatar left>
                <v-icon small>mdi-account-circle</v-icon>
              </v-avatar>
              Primary Contact
            </v-chip>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-subtitle class="pt-1"> Credit Information </v-card-subtitle>

      <div class="pb-5 px-5">
        <v-row>
          <v-col>
            <DataValue
              label="Date of Birth"
              :value="$helpers.formatDate(dateOfBirth)"
              icon="cake-variant"
              icon-color="orange lighten-2"
            />
          </v-col>
        </v-row>
      </div>

      <v-card-text>
        <div class="pb-3">Contact Addresses</div>
        <EmptyCard
          content="This contact doesn't have addresses"
          v-if="!contactAddresses.length"
          @button-click="addNewAddress"
        />
        <v-slide-group v-else>
          <v-slide-item
            v-for="address in contactAddresses"
            :key="address.addressId"
            v-slot="{ toggle }"
          >
            <AddressWidget
              class="mr-3"
              :address-id="address.addressId.toString()"
              :first-line="address.add1"
              :second-line="address.add2"
              :town="address.town"
              :county="address.county"
              :post-code="address.postcode"
              :start-date="address.fromDate"
              :end-date="address.untilDate"
              no-actions
              @click="toggle"
              :allowEdit="false"
            ></AddressWidget>
          </v-slide-item>
        </v-slide-group>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn text @click="visible = false"> Close </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="blue" text @click="openFormAndClose"> Edit </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DataValue from "@/components/data-value"
import AddressWidget from "@/components/address-widget"
import { mapMutations, mapActions, mapGetters } from "vuex"
import EmptyCard from "@/components/empty-card"

export default {
  components: {
    DataValue,
    AddressWidget,
    EmptyCard,
  },
  props: {
    fullName: {
      type: String,
      default: "",
    },
    dateOfBirth: {
      type: Number,
      default: () => 0,
    },
    primaryContact: {
      type: Boolean,
      default: false,
    },
    contactId: {
      type: String,
      default: "",
    },
  },
  data: () => ({ visible: false, currentWindow: 0 }),
  computed: {
    ...mapGetters("contacts", ["contactAddresses"]),
  },
  created() {
    this.selectContact(this.contactId)
  },
  methods: {
    ...mapMutations("contacts", {
      selectContact: "setSelectedContact",
    }),
    ...mapActions("contacts", ["fetchContactAddresses"]),
    ...mapActions("ui", ["openForm"]),
    addNewAddress() {
      this.visible = false
      this.openForm({ formName: "credit-info" })
    },
    openFormAndClose() {
      this.visible = false
      this.selectContact(0)
      setTimeout(() => {
        this.selectContact(this.contactId)
      }, 1000)
      this.openForm({ formName: "credit-info", edit: true })
    },
  },
}
</script>
